import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AdvanceTableService } from '../advance-table/advance-table.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationserviceService {

  constructor(public http : HttpClient , public userService : AdvanceTableService) { }

  signup(userdata:any):Observable<any>{
      const routeLink = `${environment.API_DEV_URL}/user/signup`;
      return this.http.post(routeLink , userdata)
  }

  loginUser(userCredentials:any){
    const routeLink = `${environment.API_DEV_URL}/user/login`;
    return this.http.post(routeLink , userCredentials)
  }
  forgotpassword(userCredentials:any){
    const routeLink = `${environment.API_DEV_URL}/user/forgotpassword`;
    return this.http.post(routeLink , userCredentials)
  }
  randompassword(userCredentials:any){
    const routeLink = `${environment.API_DEV_URL}/user/confirmpassword`;
    return this.http.post(routeLink , userCredentials)
  }

  updatePassword(passwordCredentials:any,id:any){
    const routeLink = `${environment.API_DEV_URL}/user/reset/${id}`;
    return this.http.patch(routeLink , passwordCredentials)

  }
  passwordReset(userdata:any):Observable<any>{
    const routeLink = `${environment.API_DEV_URL}/auth/reset-password`;
   return this.http.post(routeLink ,userdata)
   }
   forgotPasswordReset(userdata:any):Observable<any>{
    const routeLink = `${environment.API_DEV_URL}/auth/forgot-password`;
    return this.http.post(routeLink ,userdata)
   }
   resendConfirmation(userdata:any):Observable<any>{
    const routeLink = `${environment.API_DEV_URL}/auth/resend-confirmation`;
    return this.http.post(routeLink ,userdata)
   }
   linkExpireStatus(link : any){
    return this.http.post(`${environment.API_DEV_URL}/user/linkExpires` , {link})
   }

}
