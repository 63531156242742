import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthenticationserviceService } from '../authentication/authenticationservice.service';

@Component({
  selector: 'app-forgotlinkexpire',
  templateUrl: './forgotlinkexpire.component.html',
  styleUrls: ['./forgotlinkexpire.component.sass']
})
export class ForgotlinkexpireComponent implements OnInit {

  loginForm: UntypedFormGroup;
  response=false;
  submitted = false;
  returnUrl: string;
  dataSourc: any;
  error: string;


  constructor(private router: Router,private formBuilder: UntypedFormBuilder,   private snackBar: MatSnackBar,
    private auth: AuthenticationserviceService) { }
 ngOnInit() {

 }
 open(){
  this.router.navigate(['/authentication/forgot-password']);
 }
}
